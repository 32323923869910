// src/components/test/QuestionDisplay/components/OptionsGrid/OptionsGrid.js
import React, { useState } from 'react';
import MathDisplay from '../../../../common/MathDisplay';

const OptionsGrid = ({ options, selectedAnswer, onSelectAnswer, isDisabled }) => {
  const [hoveredOption, setHoveredOption] = useState(null);

  // Helper function to get background styles based on state
  const getOptionStyles = (key) => {
    const isSelected = key === selectedAnswer;
    const isHovered = key === hoveredOption;

    return `
      group w-full text-left rounded-xl border-2 transition-all duration-200
      ${isSelected 
        ? 'border-purple-300 bg-purple-50 ring-2 ring-purple-200' 
        : 'border-gray-200 hover:border-purple-200'}
      ${isHovered && !isSelected ? 'bg-purple-50/50' : ''}
      ${isDisabled ? 'opacity-60 cursor-not-allowed' : 'cursor-pointer'}
      overflow-hidden focus:outline-none focus:ring-2 focus:ring-purple-200
      focus:border-purple-300
    `;
  };

  // Helper function to get option circle styles
  const getOptionCircleStyles = (key) => {
    const isSelected = key === selectedAnswer;
    const isHovered = key === hoveredOption;

    return `
      flex-none flex items-center justify-center
      w-12 h-12 rounded-full transition-all duration-200
      ${isSelected 
        ? 'bg-purple-600 text-white ring-2 ring-purple-200' 
        : isHovered
          ? 'bg-purple-100 text-purple-600'
          : 'bg-gray-100 text-gray-600'}
    `;
  };

  return (
    <div className="flex flex-col font-medium gap-3 pb-24 lg:pb-4">
      {Object.entries(options || {}).map(([key, value]) => (
        <button
          key={key}
          onClick={() => !isDisabled && onSelectAnswer(key)}
          onMouseEnter={() => setHoveredOption(key)}
          onMouseLeave={() => setHoveredOption(null)}
          disabled={isDisabled}
          className={getOptionStyles(key)}
        >
          <div className="flex items-start gap-4 p-4">
            {/* Option Circle (A, B, C, D) */}
            <div className={getOptionCircleStyles(key)}>
              <span className="text-lg font-semibold">{key}</span>
            </div>

            {/* Option Content */}
            <div className="flex-1 flex items-center min-h-[3rem] py-1">
              <div className="text-base sm:text-lg text-gray-800">
                <MathDisplay content={value || ''} />
              </div>
            </div>

            {/* Selected Indicator (Visible on larger screens) */}
            {(key === selectedAnswer || key === hoveredOption) && (
              <div className={`
                hidden lg:flex items-center
                transition-opacity duration-200
                ${key === selectedAnswer ? 'opacity-100' : 'opacity-50'}
              `}>
                <svg 
                  className="w-6 h-6 text-purple-600" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M5 13l4 4L19 7" 
                  />
                </svg>
              </div>
            )}
          </div>

          {/* Mobile Selection Indicator */}
          {key === selectedAnswer && (
            <div className="absolute right-4 top-1/2 -translate-y-1/2 lg:hidden">
              <svg 
                className="w-6 h-6 text-purple-600" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M5 13l4 4L19 7" 
                />
              </svg>
            </div>
          )}
        </button>
      ))}

      {/* Spacer for mobile fixed bottom buttons */}
      <div className="h-20 lg:hidden" aria-hidden="true" />
    </div>
  );
};

export default OptionsGrid;