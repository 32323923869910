// src/components/test/QuestionDisplay/components/QuestionHeader/QuestionHeader.js
import React from 'react';
import Timer from '../Timer/Timer';
import { ProgressBar, ProgressDots } from '../Progress';

const DifficultyBadge = ({ level }) => {
  const getColor = () => {
    if (level ==1) return 'bg-blue-100 text-blue-800 ring-blue-500/30';
    if (level <= 2) return 'bg-green-100 text-green-800 ring-green-500/30';
    if (level <= 3) return 'bg-yellow-100 text-yellow-800 ring-yellow-500/30';
    return 'bg-orange-100 text-orange-800 ring-orange-500/30';
  };

  return (
    <div className={`
      inline-flex items-center gap-1.5 px-2.5 py-1 
      rounded-full text-xs font-semibold ring-1 ring-inset
      ${getColor()}
    `}>
      <svg className="w-3.5 h-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
      </svg>
      Level {level}
    </div>
  );
};

const QuestionCounter = ({ current, total }) => (
  <div className="flex items-center gap-2">
    <span className="text-lg font-bold text-purple-600">
      {current}
    </span>
    <span className="text-sm text-gray-500">
      of {total} Questions
    </span>
  </div>
);

const ProgressIndicator = ({ progress, showLabel = true }) => (
  <div className="space-y-1.5">
    {showLabel && (
      <div className="flex justify-between text-xs text-gray-600">
        <span>Overall Progress</span>
        <span>{Math.round(progress)}%</span>
      </div>
    )}
    <ProgressBar 
      progress={progress} 
      className="h-1.5" 
    />
  </div>
);

const QuestionHeader = ({ 
  questionNumber, 
  totalQuestions, 
  difficulty, 
  timeRemaining,
  isMobile = false,
  progress,
  answers,
  currentIndex,
  studentName
}) => {
  const mobileHeader = (
    <div className="sticky top-0 z-20 bg-white shadow-sm">
      {/* Top Section */}
      <div className="px-4 py-3 border-b border-gray-100">
        <div className="flex items-center justify-between mb-3">
          <QuestionCounter 
            current={questionNumber} 
            total={totalQuestions} 
          />
          <Timer 
            timeRemaining={timeRemaining}
            variant="compact"
          />
        </div>
        
        {/* Progress Section */}
        <ProgressIndicator 
          progress={progress || (questionNumber / totalQuestions) * 100} 
        />
      </div>

      {/* Bottom Section */}
      <div className="px-4 py-2 bg-gray-50/80 backdrop-blur-sm">
        <div className="flex items-center justify-between">
          <DifficultyBadge level={difficulty} />
        </div>

        {/* Question Dots */}
        <div className="mt-2">
          {/* <ProgressDots
            currentIndex={currentIndex}
            totalQuestions={totalQuestions}
            answers={answers}
          /> */}
        </div>
      </div>
    </div>
  );

  const desktopHeader = (
    <div className="bg-white border-b border-gray-200">
      {/* Main Header */}
      <div className="max-w-7xl mx-auto px-6 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-6">
            {/* Question Counter & Difficulty */}
            <div className="flex items-center gap-4">
              <QuestionCounter 
                current={questionNumber} 
                total={totalQuestions} 
              />
              <div className="h-4 w-px bg-gray-200" />
              <DifficultyBadge level={difficulty} />
            </div>
          </div>

          {/* Right Section */}
          <div className="flex items-center gap-6">
            
            <Timer 
              timeRemaining={timeRemaining}
              className="min-w-[140px]"
            />
          </div>
        </div>
      </div>

      {/* Progress Bar */}
      <div className="h-1 bg-gray-100">
        <div 
          className="h-full bg-purple-600 transition-all duration-300"
          style={{ width: `${progress || (questionNumber / totalQuestions) * 100}%` }}
        />
      </div>
    </div>
  );

  return isMobile ? mobileHeader : desktopHeader;
};

export default QuestionHeader;