import React from 'react';
import { useWindowSize } from 'react-use';

const ProgressDots = ({ currentIndex, totalQuestions, answers, className = '' }) => {
  const { width } = useWindowSize();
  const isMobile = width < 1024; // lg breakpoint

  // For mobile, show only a window of dots around current question
  const getMobileVisibleDots = () => {
    const windowSize = 5; // Show 5 dots at a time on mobile
    const start = Math.max(0, currentIndex - Math.floor(windowSize / 2));
    const end = Math.min(totalQuestions, start + windowSize);
    
    return Array.from({ length: totalQuestions }, (_, index) => {
      if (index < start) return 'before';
      if (index >= end) return 'after';
      return index;
    });
  };

  const getDotStatus = (index) => {
    if (index === currentIndex) return 'current';
    if (answers?.[index]?.student_answer === 'IDK') return 'idk';
    if (answers?.[index]) return 'answered';
    return 'unattempted';
  };

  // Desktop version
  const DesktopDots = () => (
    <div className={`hidden lg:flex flex-wrap gap-2 ${className}`}>
      {[...Array(totalQuestions)].map((_, index) => (
        <div key={index} className="flex flex-col items-center gap-1">
          <div
            className={`
              w-8 h-8 rounded-full flex items-center justify-center text-sm font-medium
              transition-all duration-200 border-2
              ${getDotStatus(index) === 'current' ? 'bg-purple-600 text-white border-purple-300' :
                getDotStatus(index) === 'idk' ? 'bg-orange-500 text-white border-orange-300' :
                getDotStatus(index) === 'answered' ? 'bg-green-500 text-white border-green-300' :
                'bg-gray-100 text-gray-600 border-gray-200'}
            `}
          >
            {index + 1}
          </div>
        </div>
      ))}
    </div>
  );

  // Mobile version
  const MobileDots = () => {
    const visibleDots = getMobileVisibleDots();
    
    return (
      <div className={`lg:hidden flex items-center justify-center gap-2 ${className}`}>
        {visibleDots[0] === 'before' && (
          <div className="flex items-center gap-1">
            <div className="w-2 h-2 rounded-full bg-gray-300" />
            <div className="w-2 h-2 rounded-full bg-gray-300" />
            <div className="w-2 h-2 rounded-full bg-gray-300" />
          </div>
        )}
        
        {visibleDots.map((dot, index) => {
          if (dot === 'before' || dot === 'after') return null;
          
          return (
            <div
              key={dot}
              className={`
                relative group
                ${index === Math.floor(visibleDots.length / 2) ? 'scale-125' : ''}
              `}
            >
              <div
                className={`
                  w-3 h-3 rounded-full transition-all duration-200
                  ${getDotStatus(dot) === 'current' ? 'bg-purple-600 ring-2 ring-purple-200' :
                    getDotStatus(dot) === 'idk' ? 'bg-orange-500' :
                    getDotStatus(dot) === 'answered' ? 'bg-green-500' :
                    'bg-gray-200'}
                `}
              />
              
              {/* Tooltip */}
              <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden group-hover:block">
                <div className="bg-gray-800 text-white text-xs rounded px-2 py-1 whitespace-nowrap">
                  Question {dot + 1}
                </div>
              </div>
            </div>
          );
        })}
        
        {visibleDots[visibleDots.length - 1] === 'after' && (
          <div className="flex items-center gap-1">
            <div className="w-2 h-2 rounded-full bg-gray-300" />
            <div className="w-2 h-2 rounded-full bg-gray-300" />
            <div className="w-2 h-2 rounded-full bg-gray-300" />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <DesktopDots />
      <MobileDots />
    </>
  );
};

export default ProgressDots;