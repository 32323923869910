// src/components/test/QuestionDisplay/hooks/useQuestionSubmission.js
import { useState, useEffect } from 'react';
import { useQuestion } from '../context/QuestionContext';
import { calculateNextDifficulty } from '../utils/difficultyCalculator';

export const useQuestionSubmission = (onComplete) => {
  const { state, actions } = useQuestion();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [studentInfo, setStudentInfo] = useState(null);

  // Load and validate student info on hook initialization
  useEffect(() => {
    try {
      const info = {
        testId: sessionStorage.getItem('testId'),
        name: sessionStorage.getItem('studentName'),
        grade: sessionStorage.getItem('studentGrade'),
        whatsapp: sessionStorage.getItem('studentWhatsapp'),
        email: sessionStorage.getItem('studentEmail')
      };

      // Log loaded info for debugging
      // console.log('Loaded student info:', info);

      // Validate all required fields
      const missingFields = Object.entries(info)
        .filter(([_, value]) => !value)
        .map(([key]) => key);

      if (missingFields.length > 0) {
        throw new Error(`Missing required student information: ${missingFields.join(', ')}`);
      }

      setStudentInfo(info);
    } catch (error) {
      console.error('Error loading student info:', error);
      actions.setError('Student information is incomplete. Please start the test again.');
    }
  }, [actions]);

  const findNextQuestion = (targetDifficulty) => {
    if (!Array.isArray(state.allQuestions)) {
      console.error('allQuestions is not an array:', state.allQuestions);
      return null;
    }

    const unusedQuestions = state.allQuestions.filter(q => 
      !state.usedQuestionIds.has(q._id)
    );

    if (unusedQuestions.length === 0) return null;

    const sortedQuestions = unusedQuestions.sort((a, b) => {
      const aDiff = Math.abs(a.difficulty - targetDifficulty);
      const bDiff = Math.abs(b.difficulty - targetDifficulty);
      return aDiff - bDiff;
    });

    return sortedQuestions[0];
  };

  const handleSubmit = async (answer = state.selectedAnswer) => {
    try {
      if (!studentInfo) {
        throw new Error('Student information is not available');
      }

      setIsSubmitting(true);
      const timeTaken = ((Date.now() - state.questionStartTime) / 1000).toFixed(1);
      const isCorrect = answer === state.currentQuestion.correct_answer;
      const date = new Date();
      const formattedDate = `${date.toDateString()} ${date.toTimeString().split(' ')[0]}`;
      
      // Prepare answer data
      const answerData = {
        test_id: studentInfo.testId,
        student_id: studentInfo.name,
        student_info: {
          name: studentInfo.name,
          grade: studentInfo.grade,
          whatsapp: studentInfo.whatsapp,
          email: studentInfo.email
        },
        question_id: state.currentQuestion._id,
        question_number: state.currentQuestionIndex + 1,
        student_answer: answer,
        correct: isCorrect,
        time_taken: parseFloat(timeTaken),
        time_stamp: formattedDate,
        difficulty_level: state.currentDifficulty
      };


      // Submit answer to API
      const response = await fetch(`${process.env.REACT_APP_API_URL}/responses`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(answerData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server response:', errorData);
        throw new Error(errorData.message || 'Failed to submit answer');
      }

      // Update local state
      actions.addAnswer({
        ...answerData,
        question_id: state.currentQuestion
      });

      // Check if this was the last question
      if (state.currentQuestionIndex >= 19) {
        await handleTestCompletion(studentInfo.testId, onComplete);
        return;
      }

      // Calculate next difficulty level
      const newDifficulty = calculateNextDifficulty(
        state.currentDifficulty,
        isCorrect,
        { minDifficulty: 1, maxDifficulty: 500 }
      );

      // Find next question
      const nextQuestion = findNextQuestion(newDifficulty);
      
      if (nextQuestion) {
        actions.setNextQuestion(
          nextQuestion,
          newDifficulty,
          nextQuestion._id
        );
      } else {
        throw new Error('No more questions available');
      }

    } catch (error) {
      console.error('Error submitting answer:', error);
      actions.setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTestCompletion = async (testId, onComplete) => {
    try {
      if (!studentInfo) {
        throw new Error('Student information is missing');
      }

      actions.setTestComplete(true);

      const completionData = {
        test_id: testId,
        student_id: studentInfo.name,
        student_info: {
          name: studentInfo.name,
          grade: studentInfo.grade,
          whatsapp: studentInfo.whatsapp,
          email: studentInfo.email
        },
        answers: state.answers,
        unattemptedQuestions: []
      };

      // console.log('Submitting test completion data:', completionData);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/end-test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(completionData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to end test');
      }

      onComplete?.(testId);
    } catch (error) {
      console.error('Error completing test:', error);
      actions.setError('Failed to complete test: ' + error.message);
    }
  };

  // Function to check if student info is loaded and valid
  const isStudentInfoValid = () => {
    return studentInfo && 
           studentInfo.name && 
           studentInfo.grade && 
           studentInfo.whatsapp && 
           studentInfo.email;
  };

  return {
    handleSubmit,
    isSubmitting,
    studentInfo,
    isStudentInfoValid: isStudentInfoValid(),
    hasError: !isStudentInfoValid()
  };
};

export default useQuestionSubmission;