// client/src/components/results/analysis/PerformanceInsights.js
import React from 'react';
import { Card } from '../../common/Card';

const PerformanceInsights = ({ summary }) => {
  return (
    <Card className="p-6">
      <h3 className="text-lg font-semibold text-[#112F3B] mb-4">Performance Insights</h3>
      
      <div className="space-y-6">
        {/* Strengths */}
        <div className="p-4 bg-[#7F56D9]/5 rounded-lg">
          <h4 className="font-medium text-[#7F56D9] mb-2">Strengths</h4>
          <ul className="space-y-2 text-gray-600">
            {summary.accuracy >= 70 && (
              <li className="flex items-center">
                <svg className="w-4 h-4 text-green-500 mr-2" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                </svg>
                High overall accuracy
              </li>
            )}
            {summary.averageTime < 30 && (
              <li className="flex items-center">
                <svg className="w-4 h-4 text-green-500 mr-2" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                </svg>
                Efficient time management
              </li>
            )}
            {summary.unattemptedCount === 0 && (
              <li className="flex items-center">
                <svg className="w-4 h-4 text-green-500 mr-2" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                </svg>
                Completed all questions
              </li>
            )}
          </ul>
        </div>

        {/* Areas for Improvement */}
        <div className="p-4 bg-[#EA4B0D]/5 rounded-lg">
          <h4 className="font-medium text-[#EA4B0D] mb-2">Areas for Improvement</h4>
          <ul className="space-y-2 text-gray-600">
            {summary.idkCount > 2 && (
              <li className="flex items-center">
                <svg className="w-4 h-4 text-[#EA4B0D] mr-2" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd"/>
                </svg>
                High number of "I Don't Know" responses
              </li>
            )}
            {summary.averageTime > 45 && (
              <li className="flex items-center">
                <svg className="w-4 h-4 text-[#EA4B0D] mr-2" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd"/>
                </svg>
                Time management needs improvement
              </li>
            )}
            {summary.accuracy < 60 && (
              <li className="flex items-center">
                <svg className="w-4 h-4 text-[#EA4B0D] mr-2" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd"/>
                </svg>
                Overall accuracy needs improvement
              </li>
            )}
          </ul>
        </div>

        {/* Recommendations */}
        <div className="p-4 bg-gray-50 rounded-lg">
          <h4 className="font-medium text-gray-700 mb-2">Recommendations</h4>
          <ul className="space-y-2 text-gray-600">
            {summary.averageTime > 30 && (
              <li className="flex items-start">
                <span className="w-2 h-2 mt-2 rounded-full bg-[#7F56D9] flex-shrink-0 mr-2"></span>
                Practice solving questions under timed conditions
              </li>
            )}
            {summary.idkCount > 2 && (
              <li className="flex items-start">
                <span className="w-2 h-2 mt-2 rounded-full bg-[#7F56D9] flex-shrink-0 mr-2"></span>
                Review concepts for questions marked as "I Don't Know"
              </li>
            )}
            <li className="flex items-start">
              <span className="w-2 h-2 mt-2 rounded-full bg-[#7F56D9] flex-shrink-0 mr-2"></span>
              Focus on improving accuracy while maintaining good time management
            </li>
          </ul>
        </div>
      </div>
    </Card>
  );
};

export default PerformanceInsights;