// src/pages/TestPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import QuestionDisplay from '../components/test/QuestionDisplay/QuestionDisplay';

// Toast component for notifications
const Toast = ({ message, type = 'info' }) => (
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 50 }}
    className={`
      fixed bottom-20 left-4 right-4 lg:left-auto lg:right-8 
      max-w-sm mx-auto lg:mx-0 p-4 rounded-lg shadow-lg
      ${type === 'warning' ? 'bg-orange-100 text-orange-800 border border-orange-200' : 
        type === 'error' ? 'bg-red-100 text-red-800 border border-red-200' :
        'bg-white text-gray-800 border border-gray-200'}
    `}
  >
    <div className="flex items-center gap-3">
      {type === 'warning' && (
        <svg className="w-5 h-5 text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
        </svg>
      )}
      {type === 'error' && (
        <svg className="w-5 h-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      )}
      <p className="flex-1">{message}</p>
    </div>
  </motion.div>
);

// Loading state with animation
const LoadingState = () => (
  <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-lg p-8 max-w-md w-full text-center"
    >
      <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-purple-100 mb-6">
        <svg className="animate-spin w-8 h-8 text-purple-600" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
          <path className="opacity-75" fill="currentColor" 
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
      </div>
      <h2 className="text-xl font-semibold text-gray-800 mb-2">
        Processing Results
      </h2>
      <p className="text-gray-600">
        Please wait while we analyze your answers...
      </p>
    </motion.div>
  </div>
);

// Error state with retry option
const ErrorState = ({ error, onRetry, onHome }) => (
  <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-lg p-8 max-w-md w-full text-center"
    >
      <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-100 mb-6">
        <svg className="w-8 h-8 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
      <h2 className="text-xl font-semibold text-gray-800 mb-2">
        Error Occurred
      </h2>
      <p className="text-gray-600 mb-6">
        {error}
      </p>
      <div className="flex flex-col sm:flex-row gap-3 justify-center">
        <button
          onClick={onRetry}
          className="px-6 py-2 bg-purple-600 text-white rounded-lg 
            hover:bg-purple-700 transition-all duration-300
            shadow-lg shadow-purple-200"
        >
          Try Again
        </button>
        <button
          onClick={onHome}
          className="px-6 py-2 bg-gray-100 text-gray-700 rounded-lg 
            hover:bg-gray-200 transition-all duration-300"
        >
          Return Home
        </button>
      </div>
    </motion.div>
  </div>
);

// Main Header component
const TestHeader = ({ studentName, onExit }) => (
  <header className="bg-white border-b border-gray-200">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between h-16">
        <div className="flex items-center gap-3">
          <h1 className="text-xl font-bold text-gray-900">Math Quotient</h1>
          <span className="px-2 py-1 bg-purple-100 text-purple-700 text-sm font-medium rounded-md hidden sm:inline-block">
            BMI
          </span>
        </div>

        <div className="flex items-center gap-6">
          {studentName && (
            <div className="flex items-center gap-3">
              <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center">
                <span className="text-sm font-medium text-purple-700">
                  {studentName.charAt(0).toUpperCase()}
                </span>
              </div>
              <span className="text-sm font-medium text-gray-700 hidden sm:inline-block">
                {studentName}
              </span>
            </div>
          )}

          <button
            onClick={onExit}
            className="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 
              hover:bg-gray-100 rounded-lg transition-colors"
          >
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
            <span className="hidden sm:inline">Exit Test</span>
          </button>
        </div>
      </div>
    </div>
  </header>
);

// BeforeUnload Warning
const useBeforeUnload = (enabled = true) => {
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (enabled) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [enabled]);
};

// Main TestPage component
const TestPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [studentName, setStudentName] = useState('');
  const [toast, setToast] = useState(null);
  const navigate = useNavigate();

  // Prevent accidental page refresh/close
  useBeforeUnload(true);

  // Check for session on mount
  useEffect(() => {
    const name = sessionStorage.getItem('studentName');
    if (name) {
      setStudentName(name);
    } else {
      navigate('/');
    }

    // Check if test was resumed
    const wasResumed = sessionStorage.getItem('testResumed');
    if (wasResumed) {
      setToast({
        message: 'Test resumed. Your previous answers have been saved.',
        type: 'info'
      });
      sessionStorage.removeItem('testResumed');
    }
  }, [navigate]);

  // Handle test completion
  const handleTestComplete = async (testId) => {
    try {
      setLoading(true);
      // Add processing animation time for better UX
      await new Promise(resolve => setTimeout(resolve, 2000));
      navigate(`/results/${testId}`);
    } catch (err) {
      console.error('Error handling test completion:', err);
      setError('Failed to process test results. Please try again.');
      setLoading(false);
    }
  };

  // Handle exit attempt
  const handleExit = () => {
    const confirmed = window.confirm(
      'Are you sure you want to exit? Your progress will be lost.'
    );
    if (confirmed) {
      sessionStorage.clear();
      navigate('/');
    }
  };

  if (loading) {
    return <LoadingState />;
  }

  if (error) {
    return (
      <ErrorState 
        error={error}
        onRetry={() => window.location.reload()}
        onHome={() => navigate('/')}
      />
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <TestHeader 
        studentName={studentName}
        onExit={handleExit}
      />
      
      <main className="flex-1 flex flex-col">
        <div className="py-6 flex-1 flex flex-col">
          {/* Welcome Message */}
          <motion.div 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-6"
          >
            <h2 className="text-2xl font-bold text-gray-900">
              Welcome, {studentName}
            </h2>
            <p className="mt-2 text-sm text-gray-600 max-w-xl mx-auto">
               Good luck!
            </p>
          </motion.div>

          {/* Question Display */}
          <div className="flex-1 flex flex-col">
            <QuestionDisplay onComplete={handleTestComplete} />
          </div>
        </div>
      </main>

      {/* Toast Notifications */}
      <AnimatePresence>
        {toast && (
          <Toast 
            message={toast.message} 
            type={toast.type}
          />
        )}
      </AnimatePresence>

      {/* Footer */}
      <footer className="bg-white border-t border-gray-200">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
          <p className="text-center text-sm text-gray-500">
            © 2024 Math Quotient. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default TestPage;