// src/components/test/QuestionDisplay/utils/difficultyCalculator.js
export const calculateNextDifficulty = (currentDifficulty, isCorrect, config = {}) => {
    const {
      maxDifficulty = 500,
      minDifficulty = 1,
      incrementStep = 1,
      decrementStep = 1
    } = config;
  
    if (isCorrect) {
      return Math.min(currentDifficulty + incrementStep, maxDifficulty);
    } else {
      return Math.max(currentDifficulty - decrementStep, minDifficulty);
    }
  };
  