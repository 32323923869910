import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import ReactConfetti from 'react-confetti';
import {
  StarIcon,
  TrophyIcon,
  RocketLaunchIcon,
  ChartBarIcon,
  BookOpenIcon,
  ClockIcon,
  LightBulbIcon,
  ExclamationTriangleIcon,
  AcademicCapIcon,
  CheckCircleIcon,
  XCircleIcon,
  QuestionMarkCircleIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  PrinterIcon,
  HomeIcon,
  AdjustmentsHorizontalIcon,
  ChevronDoubleUpIcon,
  ClipboardDocumentListIcon,
  InformationCircleIcon,
  SparklesIcon,
  BeakerIcon
} from '@heroicons/react/24/outline';

import {
  LoadingState,
  ErrorState,
  PerformanceCharts,
  DifficultyAnalysis,
  QuestionAnalysis,
  PerformanceInsights,
  ConceptAnalysis,
  ConceptRecommendations,
  TimeAnalysis,
  ConceptProgress,
  Misconceptions
} from '../components/results';

// Performance Level Templates
const performanceTemplates = {
  PERFECT: {
    icon: TrophyIcon,
    color: 'text-yellow-600',
    bgColor: 'bg-yellow-50',
    gradient: 'from-yellow-500 to-amber-600',
    title: 'Outstanding Achievement! 🏆',
    subtitle: 'Perfect Score: 100%',
    message: 'Exceptional performance! You\'ve mastered all concepts.',
    confetti: true,
    badges: [
      { text: 'Perfect Score', icon: StarIcon },
      { text: 'Master Level', icon: AcademicCapIcon },
      { text: 'Complete Understanding', icon: CheckCircleIcon }
    ],
    recommendations: [
      { text: 'Take advanced challenges', icon: ChartBarIcon },
      { text: 'Help others learn', icon: TrophyIcon },
      { text: 'Explore new topics', icon: RocketLaunchIcon }
    ]
  },
  EXCELLENT: {
    icon: StarIcon,
    color: 'text-blue-600',
    bgColor: 'bg-blue-50',
    gradient: 'from-blue-500 to-indigo-600',
    title: 'Excellent Performance! ⭐',
    subtitle: 'High Score: 80-99%',
    message: 'Great job! You\'ve shown strong understanding.',
    confetti: false,
    badges: [
      { text: 'High Achiever', icon: StarIcon },
      { text: 'Strong Performance', icon: RocketLaunchIcon },
      { text: 'Great Understanding', icon: CheckCircleIcon }
    ],
    recommendations: [
      { text: 'Focus on missed concepts', icon: LightBulbIcon },
      { text: 'Practice similar questions', icon: ChartBarIcon },
      { text: 'Aim for perfection', icon: StarIcon }
    ]
  },
  IMPROVING: {
    icon: RocketLaunchIcon,
    color: 'text-purple-600',
    bgColor: 'bg-purple-50',
    gradient: 'from-purple-500 to-purple-600',
    title: 'Keep Growing! 🚀',
    subtitle: 'Score: Below 80%',
    message: 'Good effort! Let\'s work on improvement.',
    confetti: false,
    badges: [
      { text: 'Making Progress', icon: ChartBarIcon },
      { text: 'Building Skills', icon: RocketLaunchIcon },
      { text: 'Growing Understanding', icon: LightBulbIcon }
    ],
    recommendations: [
      { text: 'Review core concepts', icon: BookOpenIcon },
      { text: 'Practice regularly', icon: ClockIcon },
      { text: 'Focus on fundamentals', icon: AcademicCapIcon }
    ]
  }
};

// Performance Badge Component
const PerformanceBadge = ({ text, icon: Icon, gradientColors }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    whileHover={{ scale: 1.05 }}
    className={`flex items-center gap-2 px-4 py-2 rounded-full 
      bg-white/10 backdrop-blur-sm border border-white/20
      transition-all duration-300 hover:bg-white/20`}
  >
    <Icon className="w-5 h-5" />
    <span className="text-sm font-medium">{text}</span>
  </motion.div>
);

// Performance Header Component
const PerformanceHeader = ({ performance, studentName, score }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const template = performanceTemplates[performance];
  const isPerfectScore = score === 100;
  const displayScore = Math.floor(score);

  return (
    <div className={`bg-gradient-to-r ${template.gradient} text-white relative overflow-hidden`}>
      {isPerfectScore && (
        <ReactConfetti
          width={windowSize.width}
          height={windowSize.height}
          numberOfPieces={200}
          recycle={false}
          gravity={0.1}
          colors={['#FFD700', '#FFA500', '#FF69B4', '#87CEEB', '#98FB98']}
        />
      )}

      <div className="max-w-7xl mx-auto px-4 py-12 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <div className="mb-8">
            <template.icon className="w-16 h-16 mx-auto mb-6" />
            <h1 className="text-4xl font-bold mb-2">{template.title}</h1>
            <p className="text-xl opacity-90 mb-4">{template.subtitle}</p>
            <p className="text-lg">
              Great work, <span className="font-semibold">{studentName}</span>!
            </p>
          </div>

          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ 
              type: "spring",
              stiffness: 260,
              damping: 20 
            }}
            className="relative inline-block"
          >
            <div className="w-40 h-40 rounded-full bg-white/10 backdrop-blur-sm
              border-4 border-white/20 flex items-center justify-center mb-8">
              <div className="text-5xl font-bold">{displayScore}%</div>
            </div>
            
            <div className="absolute -top-2 -right-2 w-6 h-6 rounded-full bg-white/20" />
            <div className="absolute -bottom-2 -left-2 w-4 h-4 rounded-full bg-white/20" />
          </motion.div>

          <div className="flex flex-wrap justify-center gap-3 mt-6">
            {template.badges.map((badge, index) => (
              <PerformanceBadge
                key={index}
                text={badge.text}
                icon={badge.icon}
                gradientColors={template.gradient}
              />
            ))}
          </div>

          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="mt-8 text-lg text-white/90"
          >
            {template.message}
          </motion.p>
        </motion.div>
      </div>
    </div>
  );
};
// QuickStats Component
const QuickStats = ({ summary }) => {
  if (!summary) return null;

  const stats = [
    {
      label: 'Correct Answers',
      value: `${summary.correctAnswers}/${summary.totalQuestions}`,
      icon: CheckCircleIcon,
      color: 'text-green-600'
    },
    {
      label: 'Accuracy Rate',
      value: `${Math.floor(summary.accuracy)}%`,
      icon: ChartBarIcon,
      color: 'text-blue-600'
    },
    {
      label: 'Average Time',
      value: `${summary.averageTime.toFixed(1)}s`,
      icon: ClockIcon,
      color: 'text-purple-600'
    },
    {
      label: 'Concepts Mastered',
      value: summary.masteredConcepts || 0,
      icon: AcademicCapIcon,
      color: 'text-yellow-600'
    }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {stats.map((stat, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className="bg-white rounded-xl shadow-sm p-6"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-500">{stat.label}</p>
              <p className={`text-2xl font-bold ${stat.color}`}>{stat.value}</p>
            </div>
            <stat.icon className={`w-8 h-8 ${stat.color}`} />
          </div>
        </motion.div>
      ))}
    </div>
  );
};

// ExpandableSection Component
const ExpandableSection = ({ 
  title, 
  subtitle, 
  icon: Icon, 
  children, 
  defaultOpen = false,
  badge = null 
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-6 py-4 flex items-center justify-between 
          hover:bg-gray-50 transition-colors"
      >
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 rounded-full bg-purple-50 flex items-center justify-center">
            <Icon className="w-5 h-5 text-purple-600" />
          </div>
          <div className="text-left">
            <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
            {subtitle && (
              <p className="text-sm text-gray-500">{subtitle}</p>
            )}
          </div>
        </div>
        <div className="flex items-center gap-3">
          {badge && (
            <span className="px-2.5 py-0.5 rounded-full text-sm font-medium 
              bg-purple-100 text-purple-700">
              {badge}
            </span>
          )}
          {isOpen ? (
            <ChevronUpIcon className="w-5 h-5 text-gray-400" />
          ) : (
            <ChevronDownIcon className="w-5 h-5 text-gray-400" />
          )}
        </div>
      </button>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="p-6 border-t border-gray-100">
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// ScrollToTop Button Component
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) { // Changed from 300 to 100 for earlier visibility
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.button
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 p-3 rounded-full bg-purple-600 text-white 
            shadow-lg hover:bg-purple-700 transition-all duration-300 z-50
            cursor-pointer print:hidden transform hover:scale-110
            flex items-center justify-center"
          style={{
            boxShadow: '0 4px 12px rgba(127, 86, 217, 0.4)'
          }}
          aria-label="Scroll to top"
        >
          <ChevronDoubleUpIcon className="w-6 h-6" />
        </motion.button>
      )}
    </AnimatePresence>
  );
};

// Main ResultsPage Component
const ResultsPage = () => {
  const { testId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [data, setData] = useState({
    results: [],
    summary: null,
    conceptAnalysis: [],
    recommendations: [],
    conceptProgress: [],
    timeAnalysis: [],
    misconceptions: []
  });

  // Fetch data useEffect
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true);
        const studentName = sessionStorage.getItem('studentName');
        
        if (!studentName || !testId) {
          throw new Error('Session information missing');
        }

        const API_URL = process.env.REACT_APP_API_URL;

        // Fetch all data in parallel
        const [
          resultsRes,
          conceptAnalysisRes,
          recommendationsRes,
          progressRes,
          timeAnalysisRes,
          misconceptionsRes
        ] = await Promise.all([
          fetch(`${API_URL}/results/${testId}`),
          fetch(`${API_URL}/concept-analysis/${testId}/${studentName}`),
          fetch(`${API_URL}/weak-concepts/${testId}/${studentName}`),
          fetch(`${API_URL}/concept-progress/${studentName}`),
          fetch(`${API_URL}/time-analysis/${testId}/${studentName}`),
          fetch(`${API_URL}/misconceptions/${testId}/${studentName}`)
        ]);

        // Process all responses
        const [
          results,
          conceptAnalysis,
          recommendations,
          progress,
          timeAnalysis,
          misconceptions
        ] = await Promise.all([
          resultsRes.json(),
          conceptAnalysisRes.json(),
          recommendationsRes.json(),
          progressRes.json(),
          timeAnalysisRes.json(),
          misconceptionsRes.json()
        ]);

        setData({
          results: results.data.results || [],
          summary: results.data.summary || null,
          conceptAnalysis: conceptAnalysis.data || [],
          recommendations: recommendations.data?.recommendations || [],
          conceptProgress: progress.data || [],
          timeAnalysis: timeAnalysis.data || [],
          misconceptions: misconceptions.data || []
        });
        
        setError('');
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [testId]);

  // Determine performance level
  const getPerformanceLevel = (score) => {
    if (score === 100) return 'PERFECT';
    if (score >= 80) return 'EXCELLENT';
    return 'IMPROVING';
  };

  const performanceLevel = getPerformanceLevel(data.summary?.accuracy || 0);
  const studentName = sessionStorage.getItem('studentName');

  // Loading and Error states
  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} onHome={() => navigate('/')} />;
  if (!data.results.length) return <ErrorState 
    error="No results found" 
    onHome={() => navigate('/')} 
  />;

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Performance Header */}
      <PerformanceHeader
        performance={performanceLevel}
        studentName={studentName}
        score={data.summary?.accuracy || 0}
      />

      <main className="max-w-7xl mx-auto px-4 py-8">
        {/* Quick Stats */}
        <section className="mb-12">
          <QuickStats summary={data.summary} />
        </section>

          {/* Understanding Mistakes Section - Moved before Question Analysis */}
          <ExpandableSection
            title="Incorrect Questions"
            subtitle="Analysis of misconceptions and errors"
            icon={ExclamationTriangleIcon}
            badge={data.misconceptions.length > 0 ? `${data.misconceptions.length} items` : null}
          >
            <Misconceptions misconceptionsData={data.misconceptions} />
          </ExpandableSection>

        {/* Analysis Sections */}
        <div className="space-y-6">
          {/* Performance Overview */}
          <ExpandableSection
            title="Performance Overview"
            subtitle="Your overall test performance analysis"
            icon={ChartBarIcon}
            defaultOpen={true}
            badge={`${data.summary.accuracy.toFixed(1)}%`}
          >
            <PerformanceCharts results={data.results} />
            <div className="mt-6">
              <PerformanceInsights summary={data.summary} />
            </div>
          </ExpandableSection>

          {/* Concept Mastery */}
          <ExpandableSection
            title="Concept Mastery"
            subtitle="Understanding of key concepts"
            icon={BookOpenIcon}
            badge={`${data.conceptAnalysis.length} concepts`}
          >
            <ConceptAnalysis conceptData={data.conceptAnalysis} />
          </ExpandableSection>

        

          {/* Question Analysis */}
          {/* <ExpandableSection
            title="Question Analysis"
            subtitle="Detailed question-by-question breakdown"
            icon={ClipboardDocumentListIcon}
            badge={`${data.results.length} questions`}
          >
            <QuestionAnalysis results={data.results} />
          </ExpandableSection> */}

          {/* Time Analysis */}
          {/* <ExpandableSection
            title="Time Management"
            subtitle="Analysis of time spent on questions"
            icon={ClockIcon}
            badge={`Avg: ${data.summary.averageTime.toFixed(1)}s`}
          >
            <TimeAnalysis conceptTimeData={data.timeAnalysis} />
          </ExpandableSection> */}

          {/* Areas for Improvement */}
          {/* <ExpandableSection
            title="Areas for Improvement"
            subtitle="Recommended focus areas"
            icon={LightBulbIcon}
            badge={data.recommendations.length > 0 ? `${data.recommendations.length} recommendations` : null}
          >
            <ConceptRecommendations recommendations={data.recommendations} />
            <div className="mt-6">
              <DifficultyAnalysis results={data.results} />
            </div>
          </ExpandableSection> */}

          {/* Progress Over Time */}
          {/* <ExpandableSection
            title="Progress Over Time"
            subtitle="Your learning journey"
            icon={ChartBarIcon}
            badge="Timeline"
          >
            <ConceptProgress progressData={data.conceptProgress} />
          </ExpandableSection> */}
        </div>
      </main>

      {/* Print Header */}
      <div className="hidden print:block print-header">
        <h1 className="text-2xl font-bold text-gray-900">Math Test Results</h1>
        <p className="text-gray-600">
          {studentName} - {new Date().toLocaleDateString()}
        </p>
      </div>

      {/* Print Footer */}
      <div className="hidden print:block print-footer">
        <p className="text-gray-500">
          Generated by Math Quotient - {new Date().toLocaleString()}
        </p>
      </div>

      {/* Scroll To Top Button */}
      <ScrollToTopButton />
    </div>
  );
};

export default ResultsPage;