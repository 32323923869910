import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  AcademicCapIcon,
  BeakerIcon,
  RocketLaunchIcon,
  BookOpenIcon,
  ChartBarSquareIcon,
  ClockIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  SparklesIcon,
  LightBulbIcon
} from '@heroicons/react/24/outline';

const ConceptAnalysis = ({ conceptData = [] }) => {
  const [selectedConcept, setSelectedConcept] = useState(null);
  const [expandedGroup, setExpandedGroup] = useState('Mastered'); // Default to showing Mastered

  // Mastery level configuration
  const masteryLevelConfig = {
    'Mastered': {
      icon: AcademicCapIcon,
      color: 'text-green-600',
      bg: 'bg-green-50',
      border: 'border-green-200',
      iconBg: 'bg-green-100',
      badge: SparklesIcon,
      description: 'Excellent understanding of these concepts'
    },
    'Proficient': {
      icon: RocketLaunchIcon,
      color: 'text-blue-600',
      bg: 'bg-blue-50',
      border: 'border-blue-200',
      iconBg: 'bg-blue-100',
      badge: BeakerIcon,
      description: 'Good grasp of these concepts'
    },
    'Developing': {
      icon: ChartBarSquareIcon,
      color: 'text-yellow-600',
      bg: 'bg-yellow-50',
      border: 'border-yellow-200',
      iconBg: 'bg-yellow-100',
      badge: LightBulbIcon,
      description: 'Making progress on these concepts'
    },
    'Needs Practice': {
      icon: BookOpenIcon,
      color: 'text-red-600',
      bg: 'bg-red-50',
      border: 'border-red-200',
      iconBg: 'bg-red-100',
      badge: ClockIcon,
      description: 'These concepts need more attention'
    }
  };

  // Order the mastery levels
  const orderedLevels = ['Mastered', 'Proficient', 'Developing', 'Needs Practice'];
  
  // Group concepts by mastery level
  const groupedConcepts = conceptData.reduce((acc, concept) => {
    const level = concept.stats.mastery_level;
    if (!acc[level]) {
      acc[level] = [];
    }
    acc[level].push(concept);
    return acc;
  }, {});

  const MasteryLevelHeader = ({ level, count }) => {
    const config = masteryLevelConfig[level];
    const Icon = config.icon;
    const Badge = config.badge;

    return (
      <div className={`flex items-center justify-between p-4 ${config.bg} rounded-t-lg`}>
        <div className="flex items-center gap-3">
          <div className={`p-2 rounded-full ${config.iconBg}`}>
            <Icon className={`w-6 h-6 ${config.color}`} />
          </div>
          <div>
            <h3 className={`font-semibold ${config.color}`}>{level}</h3>
            <p className="text-sm text-gray-600">{count} concepts</p>
          </div>
        </div>
        <Badge className={`w-5 h-5 ${config.color}`} />
      </div>
    );
  };

  const ConceptCard = ({ concept }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const config = masteryLevelConfig[concept.stats.mastery_level];

    // Calculate total questions from different difficulty levels
    const totalQuestions = concept.questions_by_difficulty 
      ? Object.values(concept.questions_by_difficulty).reduce((sum, questions) => sum + questions.length, 0)
      : concept.stats.attempted || concept.stats.total_attempted || 0;

    return (
      <motion.div
        layout
        className={`p-4 rounded-lg border ${config.border} hover:shadow-md transition-all`}
      >
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="w-full text-left"
        >
          <div className="flex justify-between items-start">
            <div className="flex items-start gap-3">
              <div className={`p-1.5 rounded-full ${config.iconBg} flex-shrink-0 mt-1`}>
                <config.badge className={`w-4 h-4 ${config.color}`} />
              </div>
              <div>
                <h4 className="font-medium text-gray-900">{concept.concept_id}</h4>
                <p className="text-sm text-gray-600">{concept.name}</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <span className={`text-sm font-medium ${config.color}`}>
                {concept.stats.accuracy.toFixed(1)}%
              </span>
              {isExpanded ? (
                <ChevronUpIcon className="w-4 h-4 text-gray-400" />
              ) : (
                <ChevronDownIcon className="w-4 h-4 text-gray-400" />
              )}
            </div>
          </div>
        </button>

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              className="mt-4 pt-4 border-t text-sm text-gray-600"
            >
              <p className="mb-3">{concept.description}</p>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-gray-500">Average Time:</p>
                  <p className="font-medium">
                    {concept.stats.average_time.toFixed(1)}s
                  </p>
                </div>
                <div>
                  <p className="text-gray-500">Questions:</p>
                  <p className="font-medium">
                    {totalQuestions || 0}
                  </p>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  };

  return (
    <div className="space-y-6">
      {/* Bar Chart section remains the same */}
      
      {/* Mastery Level Groups */}
      <div className="space-y-6">
        {orderedLevels.map(level => {
          const concepts = groupedConcepts[level] || [];
          if (concepts.length === 0) return null;

          return (
            <motion.div
              key={level}
              layout
              className="bg-white rounded-lg shadow-sm overflow-hidden"
            >
              <button
                onClick={() => setExpandedGroup(expandedGroup === level ? null : level)}
                className="w-full"
              >
                <MasteryLevelHeader level={level} count={concepts.length} />
              </button>

              <AnimatePresence>
                {expandedGroup === level && (
                  <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: "auto" }}
                    exit={{ height: 0 }}
                    className="overflow-hidden"
                  >
                    <div className="p-4 grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
                      {concepts.map(concept => (
                        <ConceptCard key={concept.concept_id} concept={concept} />
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default ConceptAnalysis;