// client/src/App.js
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { TestProvider } from './contexts/TestContext';
import AppRoutes from './routes/AppRoutes';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <TestProvider>
        <AppRoutes />
      </TestProvider>
    </BrowserRouter>
  );
}

export default App;