import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const NameInput = ({ value, onChange, disabled, error: parentError }) => {
  const [localError, setLocalError] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  
  const validateName = (name) => {
    if (!name) return '';
    
    // Trim the name to handle extra spaces
    const trimmedName = name.trim();
    
    // Basic length validation
    if (trimmedName.length < 3) {
      return 'Name must be at least 3 characters';
    }
    if (trimmedName.length > 50) {
      return 'Name is too long (maximum 50 characters)';
    }

    // Check for numbers
    if (/\d/.test(trimmedName)) {
      return 'Name cannot contain numbers';
    }

    // Check for special characters (allowing only letters, spaces, and certain special characters used in names)
    const specialCharsRegex = /[^a-zA-Z\s\-'\.]/;
    if (specialCharsRegex.test(trimmedName)) {
      return 'Name can only contain letters, hyphens, apostrophes, and periods';
    }

    // Check for multiple consecutive spaces
    if (/\s\s/.test(trimmedName)) {
      return 'Name cannot contain consecutive spaces';
    }

    // Check for multiple consecutive special characters
    if (/[-]{2,}|['.]{2,}/.test(trimmedName)) {
      return 'Name cannot contain consecutive special characters';
    }

    // Validate word count (first name, middle name, last name)
    const nameWords = trimmedName.split(/\s+/);
    if (nameWords.length > 3) {
      return 'Please enter up to 3 names only (first, middle, last)';
    }

    // Check each word's length
    const hasShortWords = nameWords.some(word => word.length < 2);
    if (hasShortWords) {
      return 'Each part of your name should be at least 2 characters';
    }

    // Check for proper capitalization
    const hasProperCapitalization = nameWords.every(word => {
      return word.charAt(0) === word.charAt(0).toUpperCase() && 
             word.slice(1) === word.slice(1).toLowerCase();
    });
    if (!hasProperCapitalization) {
      return 'Please capitalize the first letter of each name';
    }

    // Check starting/ending with special characters
    if (/^[-'.]|[-'.]$/.test(trimmedName)) {
      return 'Name cannot start or end with special characters';
    }

    // Special validation for hyphenated names
    if (trimmedName.includes('-')) {
      const hyphenatedParts = trimmedName.split('-');
      if (hyphenatedParts.some(part => part.length < 2)) {
        return 'Each part of a hyphenated name must be at least 2 characters';
      }
      if (hyphenatedParts.length > 2) {
        return 'Maximum of two parts allowed in a hyphenated name';
      }
    }

    return '';
  };

  // Auto-format name while typing
  const formatName = (name) => {
    let formatted = name
      // Replace multiple spaces with single space
      .replace(/\s+/g, ' ')
      // Remove spaces before and after hyphens
      .replace(/\s*-\s*/g, '-')
      // Capitalize first letter of each word
      .replace(/\b\w/g, letter => letter.toUpperCase())
      // Convert rest of the letters to lowercase
      .replace(/\B\w+/g, word => word.toLowerCase());
    
    return formatted;
  };

  useEffect(() => {
    if (isDirty) {
      const error = validateName(value);
      setLocalError(error);
      setIsValid(!error && value.length >= 3);
    }
  }, [value, isDirty]);

  const handleChange = (e) => {
    const formattedName = formatName(e.target.value);
    setIsDirty(true);
    onChange(formattedName);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setIsDirty(true);
  };

  const error = isDirty ? (parentError || localError) : '';

  return (
    <div className="space-y-1">
      <motion.div
        initial={false}
        animate={{
          scale: isFocused ? 1.01 : 1,
        }}
        className="relative"
      >
        <input
          type="text"
          value={value}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          className={`w-full px-4 py-3 rounded-lg border-2 bg-white
            focus:outline-none focus:ring-2 focus:ring-[#7F56D9]/20 transition-all duration-200
            ${error 
              ? 'border-red-300 focus:border-red-300' 
              : 'border-gray-200 hover:border-[#7F56D9]/30 focus:border-[#7F56D9]'
            }
            ${disabled ? 'bg-gray-50 cursor-not-allowed' : ''}
          `}
          disabled={disabled}
          placeholder="Enter your name"
          maxLength="50"
        />
        
        {/* Validation Icon */}
        {isDirty && value && (
          <motion.div 
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            className="absolute right-3 top-1/2 -translate-y-1/2"
          >
            {!isValid ? (
              <motion.svg
                className="w-5 h-5 text-red-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                initial={{ rotate: 180 }}
                animate={{ rotate: 0 }}
                transition={{ duration: 0.2 }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </motion.svg>
            ) : (
              <motion.svg
                className="w-5 h-5 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 20
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </motion.svg>
            )}
          </motion.div>
        )}
      </motion.div>

      {/* Helper Text */}
      {isFocused && !error && !isValid && (
        <motion.p
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-xs text-gray-500 mt-1"
        >
          Enter your name (first, middle, last)
        </motion.p>
      )}

      {/* Error Message */}
      {error && (
        <motion.p 
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-sm text-red-600 mt-1"
        >
          {error}
        </motion.p>
      )}

      {/* Character Count - Only show when close to max length */}
      {value.length > 40 && (
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-xs text-gray-500 text-right mt-1"
        >
          {value.length}/50
        </motion.p>
      )}
    </div>
  );
};

export default NameInput;