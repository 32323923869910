import React, { useEffect, useRef } from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';

const MathDisplay = ({ content }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current || !content) return;

    const renderMath = (text) => {
      // Handle both inline (\( \)) and display math (\[ \]) expressions
      const regexInline = /\\\((.*?)\\\)/g;
      const regexDisplay = /\\\[(.*?)\\\]/g;
      let match;
      let lastIndex = 0;
      let result = '';

      // Function to process both types of math expressions
      const processMath = (expression, isDisplay) => {
        try {
          const span = document.createElement('span');
          katex.render(expression, span, {
            throwOnError: false,
            displayMode: isDisplay,
            strict: false,
            output: 'html'  // Explicitly set output format
          });
          return span.outerHTML;
        } catch (error) {
          console.error('KaTeX error:', error);
          return expression; // Fallback to original text
        }
      };

      // Process inline math first
      while ((match = regexInline.exec(text)) !== null) {
        // Add text before the math expression
        result += text.slice(lastIndex, match.index);
        // Add the rendered math
        result += processMath(match[1], false);
        lastIndex = regexInline.lastIndex;
      }

      // Add remaining text
      text = text.slice(lastIndex);
      lastIndex = 0;

      // Then process display math in the remaining text
      while ((match = regexDisplay.exec(text)) !== null) {
        result += text.slice(lastIndex, match.index);
        result += processMath(match[1], true);
        lastIndex = regexDisplay.lastIndex;
      }

      // Add any final remaining text
      result += text.slice(lastIndex);
      return result;
    };

    try {
      containerRef.current.innerHTML = renderMath(content);

      // Add click handler for math elements if needed
      const mathElements = containerRef.current.getElementsByClassName('katex');
      Array.from(mathElements).forEach(element => {
        element.addEventListener('click', (e) => {
          e.stopPropagation(); // Prevent click propagation
        });
      });
    } catch (error) {
      console.error('Math rendering error:', error);
      containerRef.current.innerHTML = content; // Fallback to plain text
    }

    // Cleanup function
    return () => {
      if (containerRef.current) {
        const mathElements = containerRef.current.getElementsByClassName('katex');
        Array.from(mathElements).forEach(element => {
          element.removeEventListener('click', (e) => {
            e.stopPropagation();
          });
        });
      }
    };
  }, [content]);

  return (
    <div 
      ref={containerRef} 
      className="math-content text-base sm:text-lg text-gray-700 overflow-x-auto"
    />
  );
};

export default MathDisplay;