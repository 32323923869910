// client/src/pages/InstructionsPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const InstructionCard = ({ title, children, icon }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3 }}
    className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100"
  >
    <div className="flex items-start gap-3 mb-4">
      {icon}
      <h3 className="text-[#112F3B] text-lg font-semibold">{title}</h3>
    </div>
    {children}
  </motion.div>
);

const ListItem = ({ color = "purple", children }) => (
  <li className="flex items-start space-x-2">
    <span className={`w-2 h-2 mt-2 rounded-full ${
      color === "red" ? "bg-[#EA4B0D]" : "bg-[#7F56D9]"
    } flex-shrink-0`}></span>
    <span className="text-gray-600">{children}</span>
  </li>
);

const StatusIndicator = ({ color, number, label }) => (
  <div className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-50 transition-colors">
    <div className={`w-8 h-8 rounded-lg ${color} text-white flex items-center justify-center text-sm font-medium shadow-sm`}>
      {number}
    </div>
    <span className="text-gray-700">{label}</span>
  </div>
);

const ActionButton = ({ variant = "secondary", children }) => (
  <button 
    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
      ${variant === "primary" 
        ? "bg-[#7F56D9] text-white shadow-sm hover:bg-[#7F56D9]/90" 
        : "bg-gray-100 text-gray-700 hover:bg-gray-200"}`}
  >
    {children}
  </button>
);

const LoadingSpinner = () => (
  <div className="min-h-screen bg-gray-50 flex items-center justify-center">
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="text-center"
    >
      <div className="w-16 h-16 border-4 border-[#7F56D9] border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
      <p className="text-gray-600">Loading test information...</p>
    </motion.div>
  </div>
);

const InstructionsPage = () => {
  const navigate = useNavigate();
  const [studentInfo, setStudentInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const validateStudentInfo = () => {
      try {
        const info = {
          testId: sessionStorage.getItem('testId'),
          name: sessionStorage.getItem('studentName'),
          grade: sessionStorage.getItem('studentGrade'),
          whatsapp: sessionStorage.getItem('studentWhatsapp'),
          email: sessionStorage.getItem('studentEmail')
        };

        // // Log the retrieved info for debugging
        // console.log('Retrieved student info:', info);

        // Check if any required field is missing
        const missingFields = Object.entries(info)
          .filter(([_, value]) => !value)
          .map(([key]) => key);

        if (missingFields.length > 0) {
          console.error('Missing student info fields:', missingFields);
          throw new Error('Incomplete student information');
        }

        setStudentInfo(info);
        setLoading(false);
      } catch (error) {
        console.error('Student info validation error:', error);
        navigate('/');
      }
    };

    validateStudentInfo();
  }, [navigate]);

  const handleStartTest = () => {
    // Double-check student info before starting test
    if (!studentInfo) {
      console.error('Student information missing');
      navigate('/');
      return;
    }

    // Log info being passed to test
    console.log('Starting test with student info:', studentInfo);
    navigate('/test');
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white border-b border-gray-200 sticky top-0 z-10"
      >
        <div className="max-w-7xl mx-auto py-6 px-4">
          <div className="text-center">
            <h1 className="text-2xl font-bold text-[#112F3B]">Math Quotient</h1>
            <p className="text-[#EA4B0D] text-sm font-semibold italic">Math is easy, Now!</p>
          </div>
        </div>
      </motion.div>

      {/* Student Info Banner */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="bg-[#7F56D9]/5 border-b border-[#7F56D9]/10"
      >
        <div className="max-w-7xl mx-auto py-3 px-4">
          <div className="flex items-center justify-center gap-4 text-sm">
            <span className="font-medium text-[#7F56D9]">
              Student: {studentInfo?.name}
            </span>
            <span className="text-gray-500">•</span>
            <span className="text-gray-600">
              Grade {studentInfo?.grade}
            </span>
          </div>
        </div>
      </motion.div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Introduction */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center mb-8"
        >
          <h2 className="text-2xl font-bold text-[#112F3B] mb-2">Test Instructions</h2>
          <p className="text-gray-600">Please read all instructions carefully before starting</p>
        </motion.div>

        {/* Instructions Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          {/* Test Overview */}
          <InstructionCard 
            title="Test Overview" 
            icon={<svg className="w-6 h-6 text-[#7F56D9]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
            </svg>}
          >
            <ul className="space-y-3">
              <ListItem>Total Questions: 20 questions</ListItem>
              <ListItem>Time Limit: 20 minutes</ListItem>
              <ListItem>All questions must be answered in sequence</ListItem>
              <ListItem>Adaptive difficulty based on your performance</ListItem>
            </ul>
          </InstructionCard>

          {/* Progress Tracking */}
          <InstructionCard 
            title="Progress Tracking"
            icon={<svg className="w-6 h-6 text-[#7F56D9]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
            </svg>}
          >
            <div className="space-y-3">
              <p className="text-gray-600 mb-3">Your progress will be shown with these indicators:</p>
              <div className="space-y-2">
                <StatusIndicator color="bg-[#7F56D9]" number="1" label="Current question" />
                <StatusIndicator color="bg-green-500" number="2" label="Answered questions" />
                <StatusIndicator color="bg-[#EA4B0D]" number="3" label="Marked as 'I Don't Know'" />
                <StatusIndicator color="bg-gray-200" number="4" label="Upcoming questions" />
              </div>
            </div>
          </InstructionCard>

          {/* Available Actions */}
          <InstructionCard 
            title="Available Actions"
            icon={<svg className="w-6 h-6 text-[#7F56D9]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
            </svg>}
          >
            <div className="space-y-4">
              <div className="p-3 bg-[#7F56D9]/5 rounded-lg hover:bg-[#7F56D9]/10 transition-colors">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-gray-700 font-medium">Next Question</span>
                  <ActionButton variant="primary">
                    <div className="flex items-center gap-2">
                      <span>Next</span>
                      <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                      </svg>
                    </div>
                  </ActionButton>
                </div>
                <p className="text-sm text-gray-600">Select an option and click Next to proceed</p>
              </div>
              <div className="p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-gray-700 font-medium">Not Sure?</span>
                  <ActionButton>I Don't Know</ActionButton>
                </div>
                <p className="text-sm text-gray-600">Use when you're unsure of the answer</p>
              </div>
              <div className="p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-gray-700 font-medium">End Test Early</span>
                  <ActionButton>End Test</ActionButton>
                </div>
                <p className="text-sm text-gray-600">Option to finish the test before time limit</p>
              </div>
            </div>
          </InstructionCard>

          {/* Important Notes */}
          <InstructionCard 
            title="Important Notes"
            icon={<svg className="w-6 h-6 text-[#EA4B0D]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>}
          >
            <ul className="space-y-3">
              <ListItem color="red">Test auto-submits when time runs out</ListItem>
              <ListItem color="red">Select an option before clicking Next</ListItem>
              <ListItem color="red">Answers cannot be changed once submitted</ListItem>
              <ListItem color="red">Each question must be answered to proceed</ListItem>
            </ul>
          </InstructionCard>

          {/* Tips for Success */}
          <InstructionCard 
            title="Tips for Success"
            icon={<svg className="w-6 h-6 text-[#7F56D9]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
            </svg>}
          >
            <ul className="space-y-3">
              <ListItem>Read each question carefully</ListItem>
              <ListItem>Use "I Don't Know" if unsure rather than guessing</ListItem>
              <ListItem>Keep an eye on the timer</ListItem>
              <ListItem>Review your selected answer before clicking Next</ListItem>
            </ul>
          </InstructionCard>

          {/* Timer Information */}
          <InstructionCard 
            title="Timer Information"
            icon={<svg className="w-6 h-6 text-[#7F56D9]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>}
          >
            <ul className="space-y-3">
              <ListItem>Total test time: 20 minutes</ListItem>
              <ListItem>Timer is always visible</ListItem>
              <ListItem>Warning at 5 minutes remaining</ListItem>
              <ListItem>Final warning at 1 minute remaining</ListItem>
              <ListItem>Test auto-submits when timer reaches zero</ListItem>
            </ul>
          </InstructionCard>
        </div>

        {/* Action Buttons */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex flex-col sm:flex-row justify-center gap-4 sm:space-x-4 fixed bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200 lg:relative lg:bg-transparent lg:border-0"
        >
          <button
            onClick={() => navigate('/')}
            className="px-6 py-3 bg-gray-100 text-gray-700 rounded-xl hover:bg-gray-200 
              transition-all duration-300 w-full sm:w-auto flex items-center justify-center gap-2"
          >
            <svg 
              className="w-5 h-5" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
            <span>Back to Home</span>
          </button>
          <button
            onClick={handleStartTest}
            className="px-8 py-3 bg-[#7F56D9] text-white rounded-xl hover:bg-[#7F56D9]/90 
              transition-all duration-300 shadow-lg shadow-[#7F56D9]/30 w-full sm:w-auto
              flex items-center justify-center gap-2"
          >
            <span>Start Test</span>
            <svg 
              className="w-5 h-5" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M13 7l5 5m0 0l-5 5m5-5H6"
              />
            </svg>
          </button>
        </motion.div>

        {/* Terms and Privacy Notice */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="mt-8 pt-6 text-center text-xs text-gray-500"
        >
          <p>
            By starting the test, you agree to our{' '}
            <span className="text-[#7F56D9] cursor-pointer hover:underline">
              Terms of Service
            </span>
            {' '}and{' '}
            <span className="text-[#7F56D9] cursor-pointer hover:underline">
              Privacy Policy
            </span>
          </p>
        </motion.div>

        {/* Debug Info - Only shown in development */}
        {/* {process.env.NODE_ENV === 'development' && studentInfo && (
          <div className="mt-8 p-4 bg-gray-100 rounded-lg text-xs">
            <p className="font-mono">Debug Info:</p>
            <pre className="mt-2 overflow-x-auto">
              {JSON.stringify(studentInfo, null, 2)}
            </pre>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default InstructionsPage;