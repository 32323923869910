// src/components/test/QuestionDisplay/context/QuestionContext.js
import React, { createContext, useContext, useReducer } from 'react';

// Initial state
const initialState = {
  currentQuestion: null,
  selectedAnswer: '',
  questionStartTime: Date.now(),
  currentQuestionTime: 0,
  totalTimeRemaining: 1200, // 20 minutes
  currentQuestionIndex: 0,
  answers: [],
  isLoading: true,
  error: '',
  currentDifficulty: 2,
  usedQuestionIds: new Set(),
  questionsByDifficulty: {
    lower: [],
    current: [],
    higher: []
  },
  allQuestions: null,
  isTestComplete: false
};

// Action types
const ACTION_TYPES = {
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_QUESTIONS: 'SET_QUESTIONS',
  SET_CURRENT_QUESTION: 'SET_CURRENT_QUESTION',
  SET_SELECTED_ANSWER: 'SET_SELECTED_ANSWER',
  UPDATE_TIME: 'UPDATE_TIME',
  ADD_ANSWER: 'ADD_ANSWER',
  SET_NEXT_QUESTION: 'SET_NEXT_QUESTION',
  GO_TO_QUESTION: 'GO_TO_QUESTION',
  RESET_NAVIGATION: 'RESET_NAVIGATION',
  SET_TEST_COMPLETE: 'SET_TEST_COMPLETE',
  UPDATE_DIFFICULTY: 'UPDATE_DIFFICULTY',
  RESET_STATE: 'RESET_STATE'
};

// Reducer
function questionReducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    case ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

      case ACTION_TYPES.SET_QUESTIONS:
        // Initialize with a Set containing the first question's ID to prevent it from being reused
        const initialUsedIds = new Set(state.usedQuestionIds);
        const firstQuestion = action.payload.current?.[0];
        if (firstQuestion) {
          initialUsedIds.add(firstQuestion._id);
          if (firstQuestion.question_id) initialUsedIds.add(firstQuestion.question_id);
          if (firstQuestion.Question_ID) initialUsedIds.add(firstQuestion.Question_ID);
        }
      
        // Filter out any questions that match already used IDs
        const isNotUsed = (q) => !initialUsedIds.has(q._id) && 
          !initialUsedIds.has(q.question_id) && 
          !initialUsedIds.has(q.Question_ID);
      
        const lower = (action.payload.lower || []).filter(isNotUsed);
        const current = (action.payload.current || []).filter(isNotUsed);
        const higher = (action.payload.higher || []).filter(isNotUsed);
      
        // If we have a first question, remove it from the current pool
        const currentPool = firstQuestion 
          ? current.filter(q => q._id !== firstQuestion._id)
          : current;
      
        return {
          ...state,
          questionsByDifficulty: {
            lower,
            current: currentPool,
            higher
          },
          allQuestions: [...lower, ...currentPool, ...higher],
          currentQuestion: firstQuestion || null,
          usedQuestionIds: initialUsedIds,
          isLoading: false
        };

    case ACTION_TYPES.SET_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: action.payload,
        questionStartTime: Date.now()
      };

    case ACTION_TYPES.SET_SELECTED_ANSWER:
      return {
        ...state,
        selectedAnswer: action.payload
      };

    case ACTION_TYPES.UPDATE_TIME:
      return {
        ...state,
        currentQuestionTime: action.payload.currentQuestionTime,
        totalTimeRemaining: action.payload.totalTimeRemaining
      };

    case ACTION_TYPES.ADD_ANSWER:
      return {
        ...state,
        answers: [...state.answers, action.payload]
      };

    case ACTION_TYPES.SET_NEXT_QUESTION:
      const newUsedQuestionIds = new Set(state.usedQuestionIds);
      newUsedQuestionIds.add(action.payload.questionId);

      // Update the questionsByDifficulty to remove the used question
      const updateQuestionPool = (questions) => 
        questions.filter(q => q._id !== action.payload.questionId);

      return {
        ...state,
        currentQuestion: action.payload.question,
        currentDifficulty: Math.min(action.payload.difficulty, 3), // Change it if we increase the difficulty level
        currentQuestionIndex: state.currentQuestionIndex + 1,
        usedQuestionIds: newUsedQuestionIds,
        questionsByDifficulty: {
          lower: updateQuestionPool(state.questionsByDifficulty.lower),
          current: updateQuestionPool(state.questionsByDifficulty.current),
          higher: updateQuestionPool(state.questionsByDifficulty.higher)
        },
        selectedAnswer: '',
        questionStartTime: Date.now()
      };

    case ACTION_TYPES.GO_TO_QUESTION:
      return {
        ...state,
        currentQuestionIndex: action.payload.index,
        currentQuestion: action.payload.question,
        selectedAnswer: action.payload.answer || '',
        questionStartTime: Date.now()
      };

    case ACTION_TYPES.RESET_NAVIGATION:
      return {
        ...state,
        currentQuestionIndex: 0,
        currentQuestion: null,
        selectedAnswer: '',
        usedQuestionIds: new Set(),
        questionsByDifficulty: {
          lower: [],
          current: [],
          higher: []
        },
        answers: [],
        questionStartTime: Date.now()
      };

    case ACTION_TYPES.SET_TEST_COMPLETE:
      return {
        ...state,
        isTestComplete: action.payload
      };

    case ACTION_TYPES.UPDATE_DIFFICULTY:
      return {
        ...state,
        currentDifficulty: action.payload
      };

    case ACTION_TYPES.RESET_STATE:
      return {
        ...initialState,
        questionStartTime: Date.now()
      };

    default:
      return state;
  }
}

// Create Context
const QuestionContext = createContext();

// Provider Component
export function QuestionProvider({ children }) {
  const [state, dispatch] = useReducer(questionReducer, {
    ...initialState,
    questionStartTime: Date.now()
  });

  // Memoized actions
  const actions = React.useMemo(() => ({
    setLoading: (isLoading) => 
      dispatch({ type: ACTION_TYPES.SET_LOADING, payload: isLoading }),
      
    setError: (error) => 
      dispatch({ type: ACTION_TYPES.SET_ERROR, payload: error }),
      
    setQuestions: (questions) => 
      dispatch({ type: ACTION_TYPES.SET_QUESTIONS, payload: questions }),
      
    setSelectedAnswer: (answer) => 
      dispatch({ type: ACTION_TYPES.SET_SELECTED_ANSWER, payload: answer }),
      
    updateTime: (currentQuestionTime, totalTimeRemaining) => 
      dispatch({
        type: ACTION_TYPES.UPDATE_TIME,
        payload: { currentQuestionTime, totalTimeRemaining }
      }),
      
    addAnswer: (answer) => 
      dispatch({ type: ACTION_TYPES.ADD_ANSWER, payload: answer }),
      
    setNextQuestion: (question, difficulty, questionId) => 
      dispatch({
        type: ACTION_TYPES.SET_NEXT_QUESTION,
        payload: { question, difficulty, questionId }
      }),
      
    goToQuestion: (index, question, answer) => 
      dispatch({
        type: ACTION_TYPES.GO_TO_QUESTION,
        payload: { index, question, answer }
      }),
      
    resetNavigation: () => 
      dispatch({ type: ACTION_TYPES.RESET_NAVIGATION }),
      
    setTestComplete: (isComplete) => 
      dispatch({ type: ACTION_TYPES.SET_TEST_COMPLETE, payload: isComplete }),
      
    updateDifficulty: (difficulty) => 
      dispatch({ type: ACTION_TYPES.UPDATE_DIFFICULTY, payload: difficulty }),
      
    resetState: () => 
      dispatch({ type: ACTION_TYPES.RESET_STATE })
  }), []);

  const value = React.useMemo(() => ({
    state,
    dispatch,
    actions
  }), [state, actions]);

  return (
    <QuestionContext.Provider value={value}>
      {children}
    </QuestionContext.Provider>
  );
}

// Custom hook for using the context
export function useQuestion() {
  const context = useContext(QuestionContext);
  if (!context) {
    throw new Error('useQuestion must be used within a QuestionProvider');
  }
  return context;
}

// Export action types for external use
export { ACTION_TYPES };