// src/components/test/QuestionDisplay/hooks/useTestProgress.js
import { useEffect } from 'react';
import { useQuestion } from '../context/QuestionContext';

export const useTestProgress = () => {
  const { state, dispatch } = useQuestion();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        dispatch({ type: 'SET_LOADING', payload: true });
        const response = await fetch(`${process.env.REACT_APP_API_URL}/questions`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch questions');
        }

        const data = await response.json();
        dispatch({ type: 'SET_QUESTIONS', payload: data });
      } catch (error) {
        dispatch({ type: 'SET_ERROR', payload: error.message });
      } finally {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    };

    fetchQuestions();
  }, [dispatch]);

  return {
    currentQuestionIndex: state.currentQuestionIndex,
    totalQuestions: 20,
    progress: (state.currentQuestionIndex / 19) * 100
  };
};

export default useTestProgress;