// client/src/components/results/analysis/Misconceptions.js
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MathDisplay from '../../common/MathDisplay';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  XCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  BookOpenIcon,
  SparklesIcon
} from '@heroicons/react/24/outline';

// ConceptInfo component with dynamic loading
const ConceptInfo = ({ concept }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [conceptDetails, setConceptDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch concept details when opened
  useEffect(() => {
    const fetchConceptDetails = async () => {
      if (typeof concept === 'string' && !conceptDetails && isOpen) {
        setLoading(true);
        try {
          const API_URL = process.env.REACT_APP_API_URL;
          const response = await fetch(`${API_URL}/concepts/${concept}`);
          const result = await response.json();
          setConceptDetails(result.data.concept_details);
        } catch (error) {
          console.error('Error fetching concept details:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchConceptDetails();
  }, [concept, conceptDetails, isOpen]);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  // Handle both string concept IDs and concept objects
  const conceptId = typeof concept === 'string' ? concept : concept.concept_id;
  const displayDetails = conceptDetails || (typeof concept === 'object' ? concept : null);

  return (
    <div className="relative inline-block">
      <button
        onClick={handleClick}
        className="inline-flex items-center gap-1 px-3 py-1.5 rounded-full text-sm
          bg-purple-100 text-purple-700 hover:bg-purple-200 
          transition-colors focus:outline-none focus:ring-2 focus:ring-purple-400 
          focus:ring-offset-2 border border-purple-200"
      >
        <BookOpenIcon className="w-4 h-4" />
        <span>{displayDetails?.name || `Concept ${conceptId}`}</span>
      </button>
      
      <AnimatePresence>
        {isOpen && (
          <>
            <div 
              className="fixed inset-0 z-40"
              onClick={() => setIsOpen(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="absolute z-50 mt-2 bg-white rounded-lg shadow-lg border border-purple-100
                p-4 w-72 left-0 top-full"
            >
              {loading ? (
                <div className="text-center py-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-4 border-purple-600 border-t-transparent mx-auto"></div>
                  <p className="mt-2 text-sm text-gray-600">Loading concept details...</p>
                </div>
              ) : (
                <div className="flex items-start gap-2">
                  <SparklesIcon className="w-5 h-5 text-purple-600 flex-shrink-0 mt-1" />
                  <div>
                    <h4 className="font-medium text-gray-900">
                      {displayDetails?.name || `Concept ${conceptId}`}
                    </h4>
                    <p className="text-sm text-gray-600 mt-1">
                      {displayDetails?.description || 'Description not available'}
                    </p>
                    {displayDetails?.mastery_level && (
                      <div className="mt-2 pt-2 border-t border-gray-100">
                        <p className="text-sm text-purple-600 font-medium">
                          Mastery Level: {displayDetails.mastery_level}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

// MisconceptionCard Component
const MisconceptionCard = ({ item, index, totalQuestions, isOpen, onToggle }) => {
  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-sm overflow-hidden"
    >
      {/* Header */}
      <button
        onClick={onToggle}
        className="w-full px-4 py-3 flex items-start sm:items-center justify-between hover:bg-gray-50 transition-colors"
      >
        <div className="flex items-start gap-3 flex-grow">
          <div className="flex-shrink-0 w-8 h-8 mt-1 rounded-full bg-red-100 flex items-center justify-center">
            <ExclamationTriangleIcon className="w-5 h-5 text-red-600" />
          </div>
          <div className="text-left flex-grow">
            <div className="flex items-center gap-2 mb-1">
              <span className="text-sm font-medium text-gray-600">
                Question {item.question_id?.question_id || `${index + 1}`}
              </span>
              <span className="px-2 py-0.5 text-xs font-medium rounded-full bg-red-100 text-red-600">
                Incorrect
              </span>
            </div>
            <div className="text-gray-900">
              <MathDisplay content={item.question_text} />
            </div>
          </div>
        </div>
        {isOpen ? (
          <ChevronUpIcon className="w-5 h-5 text-gray-400 flex-shrink-0 ml-4" />
        ) : (
          <ChevronDownIcon className="w-5 h-5 text-gray-400 flex-shrink-0 ml-4" />
        )}
      </button>

      {/* Expanded Content */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <div className="px-4 py-3 border-t border-gray-100">
              {/* Answer Comparison */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                {/* Your Answer */}
                <div className="p-3 bg-red-50 rounded-lg">
                  <div className="flex items-center gap-2 mb-2">
                    <XCircleIcon className="w-5 h-5 text-red-600" />
                    <h5 className="text-sm font-medium text-red-800">Your Answer</h5>
                  </div>
                  <p className="text-red-700 text-sm mb-3">
                    Selected: {item.chosen_answer} <MathDisplay content={item.chosen_answer_value} />
                  </p>
                  {/* Updated Misconceptions Display */}
                  {item.concepts_missed?.length > 0 && (
                    <div className="mt-2 pt-2 border-t border-red-200">
                      <p className="text-sm font-medium text-red-800 mb-2">Key Misconceptions:</p>
                      <div className="flex flex-wrap gap-2">
                        {item.concepts_missed.map((conceptId, idx) => (
                          <ConceptInfo key={idx} concept={conceptId} />
                        ))}
                      </div>
                      <ul className="mt-2 list-disc list-inside text-sm text-red-700 space-y-1">
                        {item.misconceptions && Object.entries(item.misconceptions).map(([key, value], idx) => {
                          // Only show misconception for the chosen incorrect answer
                          if (key === `option_${item.chosen_answer.toLowerCase()}` && value) {
                            return (
                              <li key={idx} className="leading-relaxed">
                                {value}
                              </li>
                            );
                          }
                          return null;
                        })}
                      </ul>
                    </div>
                  )}
                </div>

                {/* Correct Answer */}
                <div className="p-3 bg-green-50 rounded-lg">
                  <div className="flex items-center gap-2 mb-2">
                    <CheckCircleIcon className="w-5 h-5 text-green-600" />
                    <h5 className="text-sm font-medium text-green-800">Correct Answer</h5>
                  </div>
                  <p className="text-green-700 text-sm mb-3">
                    Answer: {item.correct_answer} <MathDisplay content={item.correct_answer_value} />
                  </p>
                  {item.concepts_tested?.length > 0 && (
                    <div className="mt-2 pt-2 border-t border-green-200">
                      <p className="text-sm font-medium text-green-800 mb-2">Related Concepts:</p>
                      <div className="flex flex-wrap gap-2">
                        {item.concepts_tested.map((concept, idx) => (
                          <ConceptInfo key={idx} concept={concept} />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Time Taken */}
              <div className="flex items-center gap-2 text-sm text-gray-600">
                <ClockIcon className="w-4 h-4" />
                <span>Time Taken: {item.time_taken.toFixed(1)}s</span>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

// Main Misconceptions Component
const Misconceptions = ({ misconceptionsData = [] }) => {
  const [openItems, setOpenItems] = useState(new Set());

  const toggleItem = (index) => {
    const newOpenItems = new Set(openItems);
    if (newOpenItems.has(index)) {
      newOpenItems.delete(index);
    } else {
      newOpenItems.add(index);
    }
    setOpenItems(newOpenItems);
  };

  if (!misconceptionsData.length) {
    return (
      <div className="bg-white rounded-xl shadow-sm p-6 text-center">
        <CheckCircleIcon className="w-12 h-12 text-green-600 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          No Misconceptions Found
        </h3>
        <p className="text-gray-600">
          Great job! You haven't shown any significant misconceptions in your answers.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {misconceptionsData.map((item, index) => (
        <MisconceptionCard
          key={index}
          item={item}
          index={index}
          totalQuestions={misconceptionsData.length}
          isOpen={openItems.has(index)}
          onToggle={() => toggleItem(index)}
        />
      ))}
    </div>
  );
};

export default Misconceptions;