// client/src/components/results/charts/PerformanceCharts.js
import React from 'react';
import { Card } from '../../common/Card';
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const PerformanceCharts = ({ results }) => {
  const chartData = results.map((result, index) => ({
    question: `Q${index + 1}`,
    timeSpent: result.time_taken,
    difficulty: result.difficulty_level || result.question_id.difficulty,
    performance: result.correct ? 100 : result.student_answer === 'IDK' ? 50 : 0
  }));

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">

   {/* Difficulty Timeline */}
   <Card className="p-6">
        <h3 className="text-lg font-semibold text-[#112F3B] mb-4">Difficulty Timeline</h3>
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="question" />
              <YAxis domain={[0, 'dataMax']} tickCount={5} allowDecimals={false} />
              <Tooltip contentStyle={{ backgroundColor: 'white', borderRadius: '8px' }} />
              <Legend />
              <Area
                type="stepAfter"
                dataKey="difficulty"
                stroke="#EA4B0D"
                fill="#EA4B0D"
                fillOpacity={0.1}
                name="Difficulty Level"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Card>

      {/* Time Analysis */}
      <Card className="p-6">
        <h3 className="text-lg font-semibold text-[#112F3B] mb-4">Time Analysis</h3>
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="question" />
              <YAxis />
              <Tooltip contentStyle={{ backgroundColor: 'white', borderRadius: '8px' }} />
              <Line
                type="monotone"
                dataKey="timeSpent"
                stroke="#7F56D9"
                name="Time (seconds)"
                dot={{ fill: '#7F56D9', r: 4 }}
                activeDot={{ r: 6, fill: '#7F56D9' }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Card>
    </div>
  );
};

export default PerformanceCharts;