import React, { useState } from 'react';
import { motion } from 'framer-motion';
import NameInput from './NameInput';
import GradeSelect from './GradeSelect';
import WhatsAppInput from './WhatsAppInput';
import EmailInput from './EmailInput';

const StudentForm = ({ onSuccess }) => {
  const [formData, setFormData] = useState({
    name: '',
    grade: '',
    whatsapp: '',
    email: ''
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    grade: '',
    whatsapp: '',
    email: '',
    general: ''
  });

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';

  const validateForm = () => {
    const newErrors = {
      name: '',
      grade: '',
      whatsapp: '',
      email: '',
      general: ''
    };

    // Name validation
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    } else {
      const nameWords = formData.name.trim().split(/\s+/);
      const hasSpecialChars = /[^a-zA-Z\s]/.test(formData.name);
      
      if (nameWords.length > 3) {
        newErrors.name = 'Name cannot be more than 3 words';
      }
      if (hasSpecialChars) {
        newErrors.name = 'Name can only contain letters';
      }
    }

    // Grade validation
    if (!formData.grade) {
      newErrors.grade = 'Please select a grade';
    }

    // WhatsApp validation
    if (!formData.whatsapp.trim()) {
      newErrors.whatsapp = 'WhatsApp number is required';
    }

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    }

    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));

    if (errors[field]) {
      setErrors(prev => ({
        ...prev,
        [field]: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setErrors(prev => ({ ...prev, general: '' }));

    try {
      const response = await fetch(`${API_URL}/start-test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name.trim(),
          grade: formData.grade,
          whatsapp: formData.whatsapp,
          email: formData.email.trim().toLowerCase()
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to start test');
      }

      // Store the data in sessionStorage
      sessionStorage.setItem('testId', data.test_id);
      sessionStorage.setItem('studentName', formData.name.trim());
      sessionStorage.setItem('studentGrade', formData.grade);
      sessionStorage.setItem('studentWhatsapp', formData.whatsapp);
      sessionStorage.setItem('studentEmail', formData.email.trim().toLowerCase());
      sessionStorage.setItem('startTime', new Date().toISOString());

      onSuccess?.();

    } catch (error) {
      console.error('Error starting test:', error);
      setErrors(prev => ({
        ...prev,
        general: error.message || 'Failed to start test. Please try again.'
      }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="w-full bg-white rounded-xl shadow-2xl p-8 backdrop-blur-sm relative overflow-hidden"
    >
      {/* Gradient Border */}
      <div className="absolute top-0 right-0 w-full h-2 bg-gradient-to-r from-[#7F56D9] via-[#EA4B0D] to-[#112F3B]"></div>
      
      {/* Form Header */}
      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold text-[#112F3B] mb-2">
          Welcome to BMI
        </h2>
        <p className="text-gray-600">
          Please enter your details to begin
        </p>
      </div>

      {/* General Error Message */}
      {errors.general && (
        <motion.div 
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg"
        >
          <p className="text-sm text-red-600">{errors.general}</p>
        </motion.div>
      )}

      {/* Form */}
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Name Input */}
        <div className="space-y-1">
          <label htmlFor="name" className="block text-sm font-medium text-[#112F3B] mb-1">
            Full Name
          </label>
          <NameInput
            id="name"
            value={formData.name}
            onChange={(value) => handleInputChange('name', value)}
            disabled={loading}
            error={errors.name}
          />
        </div>

        {/* Grade Selection */}
        <div className="space-y-1">
          <label htmlFor="grade" className="block text-sm font-medium text-[#112F3B] mb-1">
            Grade Level
          </label>
          <GradeSelect
            id="grade"
            value={formData.grade}
            onChange={(e) => handleInputChange('grade', e.target.value)}
            error={errors.grade}
            disabled={loading}
          />
        </div>

        {/* WhatsApp Input */}
        <div className="space-y-1">
          <label htmlFor="whatsapp" className="block text-sm font-medium text-[#112F3B] mb-1">
            WhatsApp Number
          </label>
          <WhatsAppInput
            value={formData.whatsapp}
            onChange={(value) => handleInputChange('whatsapp', value)}
            error={errors.whatsapp}
            disabled={loading}
          />
        </div>

        {/* Email Input */}
        <div className="space-y-1">
          <label htmlFor="email" className="block text-sm font-medium text-[#112F3B] mb-1">
            Email Address
          </label>
          <EmailInput
            value={formData.email}
            onChange={(value) => handleInputChange('email', value)}
            error={errors.email}
            disabled={loading}
          />
        </div>

        {/* Submit Button */}
        <motion.button
          type="submit"
          disabled={loading}
          whileHover={{ scale: loading ? 1 : 1.02 }}
          whileTap={{ scale: loading ? 1 : 0.98 }}
          className={`w-full py-3 px-4 rounded-lg text-white font-medium 
            transition-all duration-300 relative overflow-hidden
            ${loading 
              ? 'bg-[#7F56D9]/60 cursor-not-allowed' 
              : 'bg-[#7F56D9] hover:bg-[#7F56D9]/90'
            }
            shadow-lg shadow-[#7F56D9]/30`}
        >
          {loading ? (
            <div className="flex items-center justify-center">
              <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              Starting Test...
            </div>
          ) : (
            <div className="flex items-center justify-center gap-2">
              Start Test
              <svg 
                className="w-5 h-5" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M13 7l5 5m0 0l-5 5m5-5H6"
                />
              </svg>
            </div>
          )}
        </motion.button>

        {/* Additional Information */}
        <div className="mt-6 text-center">
          <p className="text-sm text-gray-500">
            Make sure you have a stable internet connection
          </p>
        </div>
      </form>
    </motion.div>
  );
};

export default StudentForm;