// client/src/routes/AppRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import InstructionsPage from '../pages/InstructionsPage';
import TestPage from '../pages/TestPage';
import ResultsPage from '../pages/ResultsPage';
import ProtectedRoute from '../components/common/ProtectedRoute';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route 
        path="/instructions" 
        element={
          <ProtectedRoute>
            <InstructionsPage />
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/test" 
        element={
          <ProtectedRoute>
            <TestPage />
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/results/:testId" 
        element={
          <ProtectedRoute>
            <ResultsPage />
          </ProtectedRoute>
        } 
      />
    </Routes>
  );
};

export default AppRoutes;