// client/src/pages/HomePage.js
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import StudentForm from '../components/StudentForm/StudentForm';

const FeatureCard = ({ icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    className="bg-white/10 backdrop-blur-sm rounded-xl p-6 border border-white/10 hover:border-white/20 transition-colors cursor-pointer"
  >
    <div className="flex items-start gap-4">
      <div className="text-white/90 bg-white/10 p-3 rounded-lg">
        {icon}
      </div>
      <div>
        <h3 className="text-white font-semibold mb-2">{title}</h3>
        <p className="text-white/70 text-sm leading-relaxed">{description}</p>
      </div>
    </div>
  </motion.div>
);

const features = [
  {
    icon: (
      <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
      </svg>
    ),
    title: "Adaptive Testing",
    description: "Questions automatically adjust to your performance level, ensuring the right challenge for your skills."
  },
  {
    icon: (
      <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    ),
    title: "Quick Assessment",
    description: "Complete your test in just 20 minutes, with a comprehensive evaluation of your mathematical abilities."
  },
  {
    icon: (
      <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
      </svg>
    ),
    title: "Instant Results",
    description: "Get detailed feedback and analysis immediately after completing your assessment."
  },
  {
    icon: (
      <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
      </svg>
    ),
    title: "Progress Tracking",
    description: "Monitor your improvement over time with detailed performance analytics and insights."
  }
];

const HomePage = () => {
  const navigate = useNavigate();

  const handleFormSuccess = useCallback(() => {
    try {
      // Check for required session storage items
      const requiredFields = [
        'testId',
        'studentName',
        'studentGrade',
        'studentWhatsapp',
        'studentEmail'
      ];

      const sessionData = {};
      const missingFields = [];

      requiredFields.forEach(field => {
        const value = sessionStorage.getItem(field);
        if (!value) {
          missingFields.push(field);
        }
        sessionData[field] = value;
      });

      if (missingFields.length > 0) {
        console.error('Missing required fields:', missingFields);
        throw new Error(`Missing required information: ${missingFields.join(', ')}`);
      }

      // Log navigation attempt
      console.log('Navigating to instructions with session data:', sessionData);

      // Navigate to instructions page
      navigate('/instructions');
    } catch (error) {
      console.error('Navigation error:', error);
      // You might want to show an error message to the user here
    }
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#112F3B] to-[#1a4459] relative overflow-hidden">
      {/* Background Pattern - Only visible on desktop */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none hidden lg:block">
        <div className="absolute -top-1/2 -right-1/2 w-full h-full opacity-5">
          <svg viewBox="0 0 100 100" className="w-full h-full">
            <defs>
              <pattern id="grid" width="10" height="10" patternUnits="userSpaceOnUse">
                <path d="M 10 0 L 0 0 0 10" fill="none" stroke="white" strokeWidth="0.5" />
              </pattern>
            </defs>
            <rect width="100" height="100" fill="url(#grid)" />
          </svg>
        </div>
      </div>

      {/* Mobile View - Only StudentForm */}
      <div className="lg:hidden min-h-screen flex flex-col justify-center p-4">
        <div className="text-center mb-8">
          <motion.h1 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-3xl font-bold text-white mb-2"
          >
            Math Quotient
          </motion.h1>
          <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="text-[#EA4B0D] text-lg font-semibold italic"
          >
            Math is easy, Now!
          </motion.p>
        </div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="w-full max-w-md mx-auto"
        >
          <StudentForm onSuccess={handleFormSuccess} />
        </motion.div>
      </div>

      {/* Desktop View - Full Content */}
      <div className="hidden lg:block max-w-7xl mx-auto px-4 py-12 lg:py-20 relative z-10">
        <div className="flex flex-row items-center justify-between gap-12">
          {/* Left Side - Content */}
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="w-1/2"
          >
            <h1 className="text-5xl font-bold text-white mb-6">
              Math Quotient
            </h1>
            <motion.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-[#EA4B0D] text-xl font-semibold italic mb-8"
            >
              Math is easy, Now!
            </motion.p>
            <motion.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
              className="text-white/80 text-lg mb-12 max-w-xl"
            >
              Experience a personalized math assessment that adapts to your skill level. 
              Start your journey to mathematical excellence today with our innovative testing platform.
            </motion.p>

            {/* Features Grid */}
            <div className="grid grid-cols-2 gap-4 mb-8">
              {features.map((feature, index) => (
                <motion.div
                  key={feature.title}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 + index * 0.1 }}
                >
                  <FeatureCard {...feature} />
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Right Side - Form */}
          <motion.div 
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="w-1/2 max-w-md"
          >
            <StudentForm onSuccess={handleFormSuccess} />
          </motion.div>
        </div>

        {/* Footer */}
        <motion.footer 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
          className="text-center mt-8"
        >
          <p className="text-white/50 text-sm">© 2024 Math Quotient. All rights reserved.</p>
        </motion.footer>
      </div>

      {/* Development Debug Panel */}
      {/* {process.env.NODE_ENV === 'development' && (
        <div className="fixed bottom-4 left-4 p-4 bg-black/80 text-white text-xs rounded-lg">
          <p className="font-mono mb-2">Session Storage:</p>
          <pre className="overflow-x-auto max-w-md">
            {JSON.stringify(
              Object.fromEntries(
                Object.keys(sessionStorage).map(key => [key, sessionStorage.getItem(key)])
              ),
              null,
              2
            )}
          </pre>
        </div>
      )} */}
    </div>
  );
};

export default HomePage;