// src/components/test/QuestionDisplay/QuestionDisplay.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { QuestionProvider, useQuestion } from './context/QuestionContext';
import useQuestionTimer from './hooks/useQuestionTimer';
import useQuestionSubmission from './hooks/useQuestionSubmission';
import useTestProgress from './hooks/useTestProgress';
import QuestionHeader from './components/QuestionHeader/QuestionHeader';
import QuestionContent from './components/QuestionContent/QuestionContent';
import OptionsGrid from './components/OptionsGrid';
import ActionButtons from './components/ActionButtons';
import { ProgressDots } from './components/Progress';
import { LoadingState, ErrorState } from './components/QuestionState';
import ConfirmationModal from '../ConfirmationModal';

// Animation variants
const pageTransition = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.3 } 
  },
  exit: { 
    opacity: 0, 
    y: -20,
    transition: { duration: 0.2 } 
  }
};

// TimeoutOverlay Component
const TimeoutOverlay = () => (
  <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50 flex items-center justify-center"
  >
    <div className="bg-white rounded-xl p-8 max-w-md w-full mx-4 shadow-2xl">
      <div className="text-center">
        <svg 
          className="w-16 h-16 text-red-500 mx-auto mb-4" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        <h2 className="text-2xl font-bold text-gray-900 mb-2">Time's Up!</h2>
        <p className="text-gray-600 mb-4">
          Your test has been automatically submitted.
        </p>
        <div className="animate-pulse">
          <p className="text-sm text-gray-500">
            Redirecting to results...
          </p>
        </div>
      </div>
    </div>
  </motion.div>
);

const QuestionDisplay = ({ onComplete }) => {
  return (
    <QuestionProvider>
      <QuestionDisplayContent onComplete={onComplete} />
    </QuestionProvider>
  );
};

const Sidebar = ({ answers, currentIndex, totalQuestions }) => (
  <div className="hidden lg:block w-72 flex-shrink-0">
    <div className="sticky top-6 bg-white rounded-xl shadow-sm border border-gray-200 p-6">
      {/* Question Grid */}
      <div className="mb-6">
        <h3 className="text-sm font-semibold text-gray-700 mb-4">
          Question Navigation
        </h3>
        <ProgressDots
          currentIndex={currentIndex}
          totalQuestions={totalQuestions}
          answers={answers}
        />
      </div>

      {/* Legend */}
      <div className="space-y-3">
        <h3 className="text-sm font-semibold text-gray-700 mb-2">
          Status Legend
        </h3>
        <div className="flex items-center gap-2 text-sm text-gray-600">
          <span className="w-3 h-3 rounded-full bg-purple-600" />
          <span>Current Question</span>
        </div>
        <div className="flex items-center gap-2 text-sm text-gray-600">
          <span className="w-3 h-3 rounded-full bg-green-500" />
          <span>Answered</span>
        </div>
        <div className="flex items-center gap-2 text-sm text-gray-600">
          <span className="w-3 h-3 rounded-full bg-orange-500" />
          <span>"I Don't Know"</span>
        </div>
        <div className="flex items-center gap-2 text-sm text-gray-600">
          <span className="w-3 h-3 rounded-full bg-gray-200" />
          <span>Not Attempted</span>
        </div>
      </div>
    </div>
  </div>
);

const QuestionDisplayContent = ({ onComplete }) => {
  const navigate = useNavigate();
  const { state, actions } = useQuestion();
  const { handleSubmit, isSubmitting } = useQuestionSubmission(onComplete);
  const { currentQuestionTime, totalTimeRemaining, isTimeUp } = useQuestionTimer();
  const { progress } = useTestProgress();
  const [showIDKModal, setShowIDKModal] = useState(false);
  const [showEndTestModal, setShowEndTestModal] = useState(false);
  const [showTimeoutOverlay, setShowTimeoutOverlay] = useState(false);
  const studentName = sessionStorage.getItem('studentName');

  // Handle time up
  useEffect(() => {
    if (isTimeUp && !state.isTestComplete) {
      const handleTimeout = async () => {
        setShowTimeoutOverlay(true);
        try {
          const testId = sessionStorage.getItem('testId');
          // Submit current state
          await handleSubmit('Unattempted');
          // Short delay for UX
          setTimeout(() => {
            navigate(`/results/${testId}`);
          }, 3000);
        } catch (error) {
          console.error('Error handling timeout:', error);
          actions.setError('Failed to submit test after timeout');
        }
      };

      handleTimeout();
    }
  }, [isTimeUp, state.isTestComplete, navigate, actions, handleSubmit]);

  const handleIDK = async () => {
    setShowIDKModal(false);
    await handleSubmit('IDK');
  };

  const handleEndTest = async () => {
    setShowEndTestModal(false);
    const testId = sessionStorage.getItem('testId');
    onComplete?.(testId);
  };

  if (state.isLoading) return <LoadingState />;
  if (state.error) {
    return (
      <ErrorState
        error={state.error}
        onRetry={() => window.location.reload()}
        onHome={() => navigate('/')}
      />
    );
  }
  if (!state.currentQuestion) {
    return (
      <ErrorState
        error="No questions available"
        onHome={() => navigate('/')}
      />
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Show timeout overlay if time is up */}
      {showTimeoutOverlay && <TimeoutOverlay />}

      {/* Mobile Header */}
      <div className="lg:hidden">
        <QuestionHeader 
          questionNumber={state.currentQuestionIndex + 1}
          totalQuestions={20}
          difficulty={state.currentDifficulty}
          timeRemaining={totalTimeRemaining}
          isMobile={true}
          progress={progress}
          answers={state.answers}
          currentIndex={state.currentQuestionIndex}
          studentName={studentName}
        />
      </div>

      {/* Desktop Header */}
      <div className="hidden lg:block">
        <QuestionHeader 
          questionNumber={state.currentQuestionIndex + 1}
          totalQuestions={20}
          difficulty={state.currentDifficulty}
          timeRemaining={totalTimeRemaining}
          isMobile={false}
          progress={progress}
          studentName={studentName}
        />
      </div>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex gap-6">
          {/* Sidebar */}
          <Sidebar 
            answers={state.answers}
            currentIndex={state.currentQuestionIndex}
            totalQuestions={20}
          />

          {/* Question Area */}
          <div className="flex-1 max-w-3xl">
            <div className="space-y-6">
              <motion.div
                key={state.currentQuestion._id}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={pageTransition}
              >
                {/* Question Content */}
                <QuestionContent 
                  content={state.currentQuestion?.question_text}
                  questionNumber={state.currentQuestionIndex + 1}
                  totalQuestions={20}
                />

                {/* Options */}
                <div className="bg-white rounded-xl shadow-sm">
                  <OptionsGrid
                    options={state.currentQuestion?.options}
                    selectedAnswer={state.selectedAnswer}
                    onSelectAnswer={(answer) => 
                      actions.setSelectedAnswer(answer)
                    }
                    isDisabled={isSubmitting || showTimeoutOverlay}
                  />
                </div>

                {/* Desktop Action Buttons */}
                <div className="hidden lg:block">
                  <ActionButtons
                    onIDK={() => setShowIDKModal(true)}
                    onSubmit={() => handleSubmit()}
                    onEndTest={() => setShowEndTestModal(true)}
                    selectedAnswer={state.selectedAnswer}
                    isSubmitting={isSubmitting}
                    timeRemaining={totalTimeRemaining}
                    questionNumber={state.currentQuestionIndex + 1}
                    totalQuestions={20}
                    disabled={showTimeoutOverlay}
                  />
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </main>

      {/* Mobile Action Buttons */}
      <div className="lg:hidden">
        <ActionButtons
          onIDK={() => setShowIDKModal(true)}
          onSubmit={() => handleSubmit()}
          onEndTest={() => setShowEndTestModal(true)}
          selectedAnswer={state.selectedAnswer}
          isSubmitting={isSubmitting}
          timeRemaining={totalTimeRemaining}
          questionNumber={state.currentQuestionIndex + 1}
          totalQuestions={20}
          disabled={showTimeoutOverlay}
        />
      </div>

      {/* Modals */}
      {showIDKModal && !showTimeoutOverlay && (
        <ConfirmationModal
          message="Are you sure you want to skip this question?"
          onConfirm={handleIDK}
          onCancel={() => setShowIDKModal(false)}
        />
      )}
      
      {showEndTestModal && !showTimeoutOverlay && (
        <ConfirmationModal
          message="Are you sure you want to end the test? Remaining questions will be marked as unattempted."
          onConfirm={handleEndTest}
          onCancel={() => setShowEndTestModal(false)}
        />
      )}
    </div>
  );
};

export default QuestionDisplay;